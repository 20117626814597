#header {
    background-color: #fff;
    color: #000;
    position: relative;
    min-height: 105px;
    border: 0px;
    border-top: 5px solid #d81921;
}
#call-button {
    max-height: 3rem;
}
@media screen and (max-width: 769px) {
    #header {
        min-height: unset !important;
    }
}
@media screen and (max-width: 540px) {
    #call-button {
        border: 0px;
        font-weight: normal;
        font-size: 0.8rem;
        color: gray;
        padding: 0px;
        .icon-phone {
            filter: invert(5%);
        }
    }
}
#call-button:hover .icon-phone {
    filter: invert(100%);
}
