#course-intro .card {
    min-height: 100%;
}
#intro-text {
    background-color: transparent;

    .card-footer {
        background-color: rgba(242, 240, 255, 0.1);
    }
}

@media screen and (max-width: 540px) {
    #intro-text h1 {
        font-size: calc(1.345rem + 1.14vw);
    }
}
