$theme-colors: (
    'secondary': #7c7b7b,
    'danger': #d81921,
);
$cream: #fbf5e0;
$font-size-base: 1rem;
$spacer: 1rem;
$font-family-sans-serif: 'IBM Plex Sans', 'Roboto', 'Helvetica Neue', sans-serif;
$body-color: #000 !default;
$body-bg: #f7fafc !default;
$headings-font-family: 'Poppins', 'Roboto', 'Helvetica Neue', sans-serif;
$headings-font-weight: 700;
$headings-line-height: 1.15;

$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.6 !default;
$h3-font-size: $font-size-base * 2.2 !default;
$h4-font-size: $font-size-base * 1.8 !default;
$h5-font-size: $font-size-base * 1.4 !default;
$headings-margin-bottom: $spacer * 1 !default;
$h6-font-size: $font-size-base;
$btn-font-family: 'Poppins', 'Roboto', 'Helvetica Neue', sans-serif;
$btn-line-height: 1;
$btn-font-weight: 700;
$btn-border-radius: 0px;
$input-btn-border-width: 2px;
html,
body {
    height: 100%;
}
body {
    //font-size: clamp(0.9375rem, 0.875rem + 0.3125vw, 1.25rem);
    letter-spacing: 0.2px;
}

.form-label {
    font-family: $headings-font-family;
    font-weight: 600;
    line-height: 1;
}
.bg-cream {
    background-color: $cream !important;
}
.text-cream {
    color: $cream !important;
}
.fw-medium {
    font-weight: 600 !important;
}
.body-ff {
    font-family: 'IBM Plex Sans', 'Roboto', 'Helvetica Neue', sans-serif;
}
#banner {
    color: $body-bg;
    position: relative;
}
.mt-n5 {
    margin-top: -5rem !important;
}
.x-small {
    font-size: 70% !important;
}
.xs-small {
    font-size: 60% !important;
}
.skippy {
    background-color: #d81921;
}
.skippy a {
    color: $cream;
}
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~bootstrap/scss/bootstrap';
