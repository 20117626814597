#curriculum-section {
    background-color: #d81921;
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
}
@media screen and (max-width: 769px) {
    #curriculum-section {
        background-image: none !important;
    }
}
.red-gradient {
    background: linear-gradient(
        90deg,
        rgba(216, 25, 33, 0.85) 0%,
        rgb(216, 25, 33) 100%
    );
    mix-blend-mode: normal;
}
