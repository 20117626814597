.form-control::-webkit-input-placeholder {
    color: #adb5bd !important;
    opacity: 1;
}
.form-control:-moz-placeholder {
    color: #adb5bd !important;
}
.form-control::-moz-placeholder {
    color: #adb5bd !important;
}
.form-control::placeholder {
    color: #adb5bd !important;
}
.form-control:-ms-input-placeholder {
    color: #adb5bd !important;
}
select,
select option {
    color: #000000;
}
select:invalid,
select option[value=''] {
    color: #adb5bd;
}
@media screen and (min-width: 1900px) {
    #download-form {
        max-width: 650px;
        margin-left: auto;
    }
}
