.counter-list {
    margin: 0px;
    padding-left: 0;
    list-style-type: none;
}
.counter-list li {
    counter-increment: step-counter;
    position: relative;
    margin-left: 60px;
    & a {
        color: #fff;
        font-weight: bold;
        text-decoration: none;
    }
}
.counter-list li::before {
    content: counter(step-counter);
    font-family: 'Poppins', 'Roboto', 'Helvetica Neue', sans-serif;
    position: absolute;
    top: -5px;
    left: -55px;
    font-size: 24px;
    line-height: 1;
    background-color: #fbf5e0;
    color: #000;
    font-weight: bold;
    padding: 7px 12px;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    text-align: center;
    display: inline-block;
}
