#brochure-section {
    background-color: transparent;
    background-position: center right;
    background-size: cover;
}
@media screen and (max-width: 991.99px) {
    #brochure-section {
        background-image: none !important;
    }
}
.black-gradient-reverse {
    background: linear-gradient(
        90deg,
        rgb(0, 0, 0, 0.8) 0%,
        rgb(0, 0, 0, 0.65) 25%,
        rgb(0, 0, 0, 0.5) 50%,
        rgb(0, 0, 0, 0.4) 75%,
        rgb(0, 0, 0, 0.02) 100%
    );
    mix-blend-mode: normal;
}
@media screen and (max-width: 991.99px) {
    .black-gradient-reverse {
        background: transparent !important;
    }
}
