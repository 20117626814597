.slick-slide {
    padding-right: 1rem;
    margin-bottom: 0.6rem;
}
.slick-prev,
.slick-next {
    width: 30px !important;
    height: 30px !important;
    top: -25px !important;
}
.slick-prev {
    left: auto !important;
    right: 50px;
}
.slick-next {
    right: 10px !important;
}
.slick-prev:before,
.slick-next:before {
    color: grey !important;
    font-size: 30px !important;
}

#alumni .card-body {
    min-height: 150px;
}

@media screen and (max-width: 520px) {
    .slick-prev,
    .slick-next {
        width: 24px !important;
        height: 26px;
        top: -30px !important;
    }
    .slick-prev {
        right: 30px !important;
    }
    .slick-next {
        right: 0px !important;
    }
}
