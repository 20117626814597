#banner {
    background-color: #d81921;
    background-position: top center;
    background-size: cover;
    article {
        min-height: 300px;
    }
    .color-filter {
        filter: grayscale(1) brightness(5.5);
    }
}
